<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";

export default {
    page: {
        title: "Detail Pra Asesmen",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Detail Pra Asesmen",
            items: [
                {
                    text: "Validasi MUK",
                    href: "/validasi-muk",
                },
                {
                    text: "Detail Pra Asesmen",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            id: this.$route.params.id,
            optionsMenuParent: [],
            urutan: null,
            pendaftaran: [],
            pra_asesmen: [],
            pra_asesmen_asesi: [],
            pra_asesmen_asesor: [],
            hak_akses: false,
            // pra_asesmen_dokumen: [],
            pra_asesmen_timeline: [],
            next_status: [],
            status_sekarang: [],
            nomor_pra_asesmen: null,
            path_surat_tugas: null,
            sessionRoleId: localStorage.session_role_active_id,
            sessionUserId: localStorage.session_user_id,
            sessionUsername: localStorage.session_username,
            tanggal_pra_asesmen: new Date().toISOString().slice(0, 10),
            backend_url: process.env.VUE_APP_BACKEND_URL,
            tuk_selected: null,

            optionsMetodeSertifikasi: [],
            optionsAsesi: [],
            optionsTUK: [],
            optionsDokumen: [],
            optionsAsesor: [],

            //modal
            showModalSimpan: false,
            showModalSuratTugas: false,
            showModalAsesor: false,

            pemilihan_asesor: [],
            kehadiran_asesor: [],
            asesor_all: [],
            loadingTable: false,
            asesor: [],

            optionsDokumenPendaftaran: [],

            index_asesi: null,
            asesor_kompetensi_selected: null,
            status_pemilihan_asesor: false,
            tempat_uji_kompetensi: null,

            //submit kehadiran asesor
            index_kehadiran_asesor: null,
            id_kehadiran_asesor: null,
            status_kehadiran_asesor: null,
            showModalStatusKehadiran: false,
            catatan_kehadiran: null,
            keterangan_kehadiran: null,

            //modal password
            showModalTerbitkanSuratBalasan: false,
            showModalPassword: false,
            username: localStorage.session_username,
            session: JSON.parse(localStorage.session),

            //modal preview dokumen
            path_preview_dokumen: null,
            showModalPreviewDokumen: false,
            tipe_asesor: null,

            //modal verifikasi dokumen
            showModalVerifikasiDokumen: false,
            nama_dokumen: null,

            //modal validasi manager sertifikasi lsp
            statusValidasi: null,
            status_validasi: false,
            showModalValidasi: false,
            asesor_kompetensi_list: [],
            asesor_peninjau_list: [],
            loading_store_asesor: false,
            iframe_pdf_surat_tugas: null,
            disclaimer_asesor: false,
            metode_asesmen: null,
            tanggal_asesmen: null,

            //modal terbit surat tugas
            showModalTerbitkanSuratTugas: false,
            qr_path: null,
            checklist: false,

            //modal verifikasi dokumen apl 2
            modalVerifikasiDokumenAPL2: false,
            disclaimerVerifikasiForm: false,
            form_dokumen_muk: null,
            item_id: null,
            dokumen_id: null,
            sebagai_asesor: null,
            status_pengisian_dokumen: false,
            is_asesor_bersedia: false,

            //modal password verifikasi dokumen
            showModalPasswordVerifikasiDokumen: false,

            // modal preview dokumen
            formPreviewDocMUK: null,
            modalPreviewDokumenMUK: false,

            // modal dokumen pendaftarran
            linkPreviewForm: null,
            id_pendaftaran_asesi: null,
            asesi_id: null,
            showModalPreviewForm: false,
            dokumen_relevan: [],
            dokumen_persyaratan_dasar: [],
            loadingDokumen: false,
            pendaftaran_id: null,

            status_verifikasi_dokumen_apl_2: false,

            // input dokumen pra asesmen
            form_dokumen_muk_pra_asesmen: null,
            dokumen_pra_asesmen_input: [
                "b88c93e4-39dc-11ee-9a36-0242ac110009", // ceklist konsultasi pra asesmen
                "6a00c792-3ab6-11ee-92d6-0242ac110009", // MAPA 01 observasi
                "b88ca2a8-39dc-11ee-9a36-0242ac110009", // MAPA 01 porto
                "b88ca64a-39dc-11ee-9a36-0242ac110009", // MAPA 02
                "b88cb55e-39dc-11ee-9a36-0242ac110009", // AK 01
            ],
            showModalPengisianDokumen: false,
            pra_asesmen_asesi_dokumen_id: null,
        };
    },
    mounted() {
        let self = this;
        
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }

        let loadInputScript = document.createElement("script");
        loadInputScript.setAttribute("src", process.env.VUE_APP_BACKEND_URL + "/js/input.js");
        document.head.appendChild(loadInputScript);
        self.getPegawai();
        self.getAsesor();
        self.getDetailPraAsesmen();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD");
        },

        getTUKSkema(skema_id) {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-tuk-skema/" + skema_id,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsTUK = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getPegawai() {
            // GET MASTER KARYAWAN
            let self = this;
            // load data master karyawan
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pegawai",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsAsesi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAsesor() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesor-pra-asesmen",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsAsesor = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDetailPraAsesmen() {
            let self = this;
            self.loadingTable = true;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/detail",
                params: {
                    pra_asesmen_id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.pendaftaran = response_data_fix.pendaftaran;

                        self.pra_asesmen = response_data_fix.pra_asesmen;
                        self.metode_asesmen = response_data_fix.pra_asesmen.metode_nama;
                        self.tanggal_asesmen = response_data_fix.tanggal_asesmen;
                        self.urutan = response_data_fix.urutan;
                        self.pra_asesmen_asesor = response_data_fix.pra_asesmen_asesor;
                        self.status_verifikasi_dokumen_apl_2 = response_data_fix.status_verifikasi_dokumen_apl_2;
                        self.pra_asesmen_timeline = response_data_fix.pra_asesmen_timeline;
                        self.next_status = response_data_fix.next_status;
                        self.status_sekarang = response_data_fix.status;
                        // self.path_surat_tugas = self.backend_url + "/" + response_data_fix.pra_asesmen.path_surat_tugas;
                        self.status_pemilihan_asesor = response_data_fix.status_pemilihan_asesor;
                        self.optionsDokumenPendaftaran = response_data_fix.pendaftaran_asesi;
                        self.sebagai_asesor = response_data_fix.sebagai_asesor;
                        self.hak_akses = response_data_fix.hak_akses;
                        self.status_pengisian_dokumen = response_data_fix.status_pengisian_dokumen;
                        var currentDate = new Date();
                        self.nomor_pra_asesmen = response_data_fix.urutan + "/PA/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                        self.is_asesor_bersedia = response_data_fix.is_asesor_bersedia;
                        // self.cariJadwalSertifikasi(response_data_fix.pra_asesmen.jadwal_sertifikasi_id);

                        let clear_data = [];
                        $.each(response_data_fix.pra_asesmen_asesor, function (indexInArray, valueOfElement) {
                            clear_data.push({
                                id: valueOfElement.id,
                                skema_sertifikasi: valueOfElement.skema_sertifikasi,
                                asesi: valueOfElement.asesi,
                                tanggal_pelaksanaan: valueOfElement.tanggal_pelaksanaan,
                                tuk: null,
                                asesor: [{ asesor_kompetensi: null, asesor_peninjau: null, status_kesediaan_ak: null, status_kesediaan_ap: null }],
                            });
                        });
                        self.skema_id = response_data_fix.pra_asesmen.skema_id;
                        self.getTUKSkema(response_data_fix.pra_asesmen.skema_id);
                        self.pemilihan_asesor = clear_data;

                        let clear_data_asesi = [];
                        $.each(response_data_fix.pra_asesmen_asesi, function (indexInArray, valueOfElement) {
                            var raw_data = valueOfElement;
                            var dok_apl_02 = valueOfElement.pendaftaran_asesi.path_dokumen_apl_2;
                            // code untuk limit view asesor kopetensi
                            // if (response_data_fix.sebagai_asesor == "Asesor Kompetensi") {
                            //     if (valueOfElement.pra_asesmen_asesor.asesor_id == self.session.pegawai_id) {
                            //         if (valueOfElement.path_dokumen_apl_2) {
                            //             dok_apl_02 = valueOfElement.path_dokumen_apl_2;
                            //         }
                            //         raw_data.dokumen_apl_2 = dok_apl_02;
                            //         clear_data_asesi.push(valueOfElement);
                            //     }
                            // } else {
                            var is_verifikasi_apl_2 = false;
                            if (valueOfElement.path_dokumen_apl_2) {
                                dok_apl_02 = valueOfElement.path_dokumen_apl_2;
                                is_verifikasi_apl_2 = true;
                            }
                            raw_data.dokumen_apl_2 = dok_apl_02;
                            raw_data.is_verifikasi_apl_2 = is_verifikasi_apl_2;
                            valueOfElement.asesor_kopetensi = valueOfElement?.pra_asesmen_asesor?.pegawai;
                            valueOfElement.asesor_peninjau = valueOfElement?.pra_asesmen_peninjau?.pegawai;
                            clear_data_asesi.push(valueOfElement);
                            // }
                        });
                        console.log(clear_data_asesi);
                        self.pra_asesmen_asesi = clear_data_asesi;

                        if (response_data_fix.pra_asesmen_asesor) {
                            let clear_data_kehadiran = [];
                            $.each(response_data_fix.pra_asesmen_asesor, function (indexInArray, valueOfElement) {
                                clear_data_kehadiran.push({
                                    skema_sertifikasi: valueOfElement.skema_nama,
                                    asesi_nama: valueOfElement.asesi_nama,
                                    tuk_nama: valueOfElement.tuk_nama,
                                    tanggal_pelaksanaan: valueOfElement.tanggal_pelaksanaan,
                                    asesor_nama: valueOfElement.asesor_nama,
                                    sebagai: valueOfElement.sebagai,
                                    status_kesediaan: valueOfElement.status_kesediaan,
                                    id: valueOfElement.id,
                                    path_apl_1: valueOfElement.path_apl_1,
                                    path_apl_2: valueOfElement.path_apl_2,
                                    path_dokumen_apl_2: valueOfElement.path_dokumen_apl_2,
                                    status_verifikasi: valueOfElement.status_verifikasi,
                                });
                            });
                            self.kehadiran_asesor = clear_data_kehadiran;
                        }

                        if (response_data_fix.asesor_all) {
                            let clear_data_asesor = [];
                            $.each(response_data_fix.asesor_all, function (indexInArray, valueOfElement) {
                                // code untuk limit view asesor kopetensi
                                if (response_data_fix.sebagai_asesor == "Asesor Kompetensi" || response_data_fix.sebagai_asesor == "Asesor Peninjau") {
                                    if (valueOfElement.asesor_id == self.session.pegawai_id) {
                                        clear_data_asesor.push({
                                            asesor_id: valueOfElement.asesor_id,
                                            asesor_nama: valueOfElement.asesor_nama,
                                            id: valueOfElement.id,
                                            status_kesediaan: valueOfElement.status_kesediaan,
                                            sebagai: valueOfElement.sebagai,
                                            asesi: valueOfElement.asesi,
                                            pegawai: valueOfElement.pegawai,
                                            data_asesor: valueOfElement.asesor,
                                        });
                                    }
                                } else {
                                    clear_data_asesor.push({
                                        asesor_id: valueOfElement.asesor_id,
                                        asesor_nama: valueOfElement.asesor_nama,
                                        id: valueOfElement.id,
                                        status_kesediaan: valueOfElement.status_kesediaan,
                                        sebagai: valueOfElement.sebagai,
                                        asesi: valueOfElement.asesi,
                                        pegawai: valueOfElement.pegawai,
                                        data_asesor: valueOfElement.asesor,
                                    });
                                }
                            });
                            self.asesor_all = clear_data_asesor;
                        }

                        if (response_data_fix.pra_asesmen_asesor_peninjau) {
                            var asesor_peninjau_list = [];
                            $.each(response_data_fix.pra_asesmen_asesor_peninjau, function (indexInArray, valueOfElement) {
                                asesor_peninjau_list.push({
                                    asesor_nama: valueOfElement.asesor,
                                });
                            });
                            self.asesor_peninjau_list = asesor_peninjau_list;
                        }

                        if (response_data_fix.pra_asesmen_asesor_kompetensi) {
                            var asesor_kompetensi_list = [];
                            $.each(response_data_fix.pra_asesmen_asesor_kompetensi, function (indexInArray, valueOfElement) {
                                asesor_kompetensi_list.push({
                                    asesor_nama: valueOfElement.asesor,
                                    asesi_nama: valueOfElement.pra_asesmen_asesi,
                                });
                            });
                            self.asesor_kompetensi_list = asesor_kompetensi_list;
                        }

                        self.loadingTable = false;

                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        cariJadwalSertifikasi(id) {
            let self = this;
            self.loadingTable = true;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/get-data-pendaftaran/" + id,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var verifikasi_tuk = response_data_fix.data;
                        console.log(response_data_fix.skema_sertifikasi_id);
                        let clear_data = [];
                        $.each(verifikasi_tuk, function (indexInArray, valueOfElement) {
                            clear_data.push({
                                id: valueOfElement.id,
                                skema_sertifikasi: valueOfElement.skema_sertifikasi,
                                asesi: valueOfElement.pegawai,
                                tanggal_pelaksanaan: valueOfElement.tanggal_pelaksanaan,
                                tuk: null,
                                asesor: [{ asesor_kompetensi: null, asesor_peninjau: null, status_kesediaan_ak: null, status_kesediaan_ap: null }],
                            });
                        });
                        self.skema_id = response_data_fix.skema_sertifikasi_id;
                        self.pemilihan_asesor = clear_data;
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        modalSuratTugas() {
            let self = this;
            self.showModalSuratTugas = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSuratTugas = true;
            });
        },
        addAsesor(index) {
            this.pemilihan_asesor[index].asesor.push({
                asesor_kompetensi: null,
                asesor_peninjau: null,
                status_kesediaan_ak: null,
                status_kesediaan_ap: null,
            });
        },
        removeAsesor(index, row) {
            this.pemilihan_asesor[index].asesor.splice(row, 1);
        },
        modalTambahAsesor(index) {
            let self = this;
            self.index_asesi = index;
            console.log(index);
            self.showModalAsesor = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalAsesor = true;
            });
            // this.noEdit = index;
            // this.pemilihan_asesor[index].asesor = this.asesor;
        },
        simpanAsesor(index) {
            this.pemilihan_asesor[index].asesor.push({
                asesor: this.asesor,
            });
            this.showModalAsesor = false;
        },
        storePemilihanAsesor() {
            let self = this;
            self.loading_store_asesor = true;
            self.iframe_pdf_surat_tugas = null;

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/store-asesor",
                data: {
                    pra_asesmen_id: self.id,
                    tanggal_pra_asesmen: self.tanggal_pra_asesmen,
                    nomor_pra_asesmen: self.nomor_pra_asesmen,
                    pra_asesmen_asesi: JSON.stringify(self.pra_asesmen_asesi),
                    tempat_uji_kompetensi: self.tempat_uji_kompetensi,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    var res_fix = response.data;
                    self.iframe_pdf_surat_tugas = res_fix.pdf;
                    self.loading_store_asesor = false;
                    // console.log(response);
                    // Swal.fire({
                    //     icon: "success",
                    //     title: "Berhasil",
                    //     text: "Halaman akan diperbarui",
                    //     timer: 2000,
                    //     timerProgressBar: true,
                    //     showCancelButton: false,
                    //     showConfirmButton: false,
                    // }).then((result) => {
                    //     /* Read more about handling dismissals below */
                    //     if (result.dismiss === Swal.DismissReason.timer) {
                    //         Swal.close();
                    //         self.getDetailPraAsesmen();
                    //     }
                    // });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        verifikasiKehadiran(status_kehadiran, id_item, index) {
            let self = this;
            self.status_kehadiran_asesor = status_kehadiran;
            self.id_kehadiran_asesor = id_item;
            self.index_kehadiran_asesor = index;
            if (status_kehadiran == "Tidak Bersedia") {
                self.showModalStatusKehadiran = true;
            } else {
                self.prosesVerifikasiKehadiran();
            }
        },
        prosesVerifikasiKehadiran() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.showModalStatusKehadiran = false;
            var index = self.index_kehadiran_asesor;
            var id_kehadiran_asesor = self.id_kehadiran_asesor;
            var keterangan_kehadiran = self.status_kehadiran_asesor;
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/ubah-status-kehadiran-asesor",
                    {
                        id_kehadiran_asesor: id_kehadiran_asesor,
                        keterangan_kehadiran: keterangan_kehadiran,
                        notes: self.catatan_kehadiran,
                        pra_asesmen_id: self.id,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.kehadiran_asesor[index] = res_fix.penyusunan_muk_asesor;
                    if (res_fix.status_kehadiran == true) {
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman list pra asesmen segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({ name: "all-pra_asesmen" });
                            }
                        });
                    } else {
                        self.getDetailPraAsesmen();
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        modalPassword() {
            let self = this;
            self.showModalPassword = false;
            self.showModalTerbitkanSuratTugas = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalTerbitkanSuratTugas = false;
                self.showModalPassword = true;
            });
        },
        checkPassword() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
                    username: this.username,
                    password: this.password,
                    is_one_time: false,
                })
                .then((response) => {
                    var data_login = response.data;
                    // check status login
                    if (data_login.status_login == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Oopss",
                            text: data_login.message_login,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    } else {
                        this.showModalPassword = false;
                        this.submitNextStatus();
                    }
                })
                .catch((error) => {
                    var message = error.response.data.data.error;
                    if (message == "Session has been used") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
                        });
                    } else if (message == "Account cant find") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Periksa Kembali Username & Password Anda!",
                        });
                    } else if (message == "Something went wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Terjadi Kesalahan, Coba lagi nanti",
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun tidak ditemukan, periksa kembali username & password anda",
                        });
                    }
                });
        },
        submitNextStatus() {
            self.showModalVerifikasiDokumen = false;
            self.showModalAsesor = false;
            this.storeUbahStatusPraAsesmen();
        },

        storeUbahStatusPraAsesmen() {
            let self = this;
            self.showModalAsesor = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/ubah-status",
                data: {
                    id: self.id,
                    next_status: self.next_status?.id,
                    tanggal_pra_asesmen: self.tanggal_pra_asesmen,
                    nomor_pra_asesmen: self.nomor_pra_asesmen,
                    tempat_uji_kompetensi: self.tempat_uji_kompetensi,
                    tanggal_asesmen: self.tanggal_asesmen,
                    metode_asesmen: self.metode_asesmen,
                    tuk_id: self.tuk_selected?.id,
                    tuk_nama: self.tuk_selected?.tuk_nama,
                    pra_asesmen_asesi: JSON.stringify(self.pra_asesmen_asesi),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman pra asesmen segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalVerifikasiDokumen = false;
                            self.$router.push({ name: "all-pra_asesmen" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        selesaikanProsesPengisian() {
            let self = this;
            self.showModalAsesor = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/selesaikan-proses-pengisian",
                data: {
                    id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman pra asesmen segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalVerifikasiDokumen = false;
                            self.$router.push({ name: "all-pra_asesmen" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        storePemilihanMetodeAsesi() {
            let self = this;
            self.showModalAsesor = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/store-metode-asesi",
                data: {
                    id: self.id,
                    pra_asesmen_asesi: JSON.stringify(self.pra_asesmen_asesi),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman pra asesmen segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalVerifikasiDokumen = false;
                            self.$router.push({ name: "all-pra_asesmen" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        modalPreviewAPL2(path, nama_dokumen) {
            let self = this;
            self.showModalPreviewDokumen = true;
            self.path_preview_dokumen = process.env.VUE_APP_BACKEND_URL + "/" + path;
            self.nama_dokumen = nama_dokumen;
            console.log(path);
        },
        modalVerifikasiDokumen() {
            let self = this;
            self.showModalVerifikasiDokumen = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalVerifikasiDokumen = true;
                self.getDataMetodeSertifikasi();
            });
        },

        statusValidasiModal(status) {
            let self = this;
            if (status == "tolak") {
                self.showModalValidasi = true;
                self.status_validasi = status;
            } else {
                self.showModalAsesor = true;
                self.status_validasi = status;
            }
        },
        modalTerbitkanSuratTugas() {
            let self = this;
            self.showModalTerbitkanSuratTugas = false;
            self.terbitkanSuratTugas();
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalTerbitkanSuratTugas = true;
            });
        },
        terbitkanSuratTugas() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_surat_tugas = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/generate-surat-tugas/" + self.id, {
                    params: {
                        id: self.id,
                        dokumen_surat_tugas: "validasi",
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.qr_path = res_fix.qr;
                    self.path_surat_tugas = res_fix.pdf;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        verifikasiDokumenMUK(dokumen_id, item_id) {
            let self = this;
            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.modalVerifikasiDokumenAPL2 = true;
            self.item_id = item_id;
            (self.dokumen_id = dokumen_id),
                axios
                    .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/get-form-dokumen-pendaftaran", {
                        params: {
                            pendaftaran_id: self.pendaftaran.id,
                            dokumen_id: dokumen_id,
                            aksi_dokumen: "verifikasi_dokumen",
                            item_id: item_id,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    })
                    .then(function (response) {
                        self.form_dokumen_muk = response.data.html;
                    })
                    .catch((e) => {
                        this.axiosCatchError = e.response;
                        Swal.close();
                    });
        },
        storeVerifikasiDokumenAPL02() {
            let self = this;
            self.modalVerifikasiDokumenAPL2 = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var form = $("#form-dokumen-pendaftaran");
            var mydata = new FormData(form[0]);
            mydata.append("pra_asesmen_asesi_id", self.item_id);
            mydata.append("dokumen_id", self.dokumen_id);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/store-verifikasi-form", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.status == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Peringatan!",
                            text: "Pengisian Form tidak lengkap!",
                            showConfirmButton: false,
                        });
                        self.modalVerifikasiDokumenAPL2 = true;
                    } else {
                        self.disclaimerVerifikasiForm = false;
                        self.getDetailPraAsesmen();
                    }
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        modalPasswordVerifikasiDokumen() {
            let self = this;
            self.showModalPasswordVerifikasiDokumen = false;
            self.modalVerifikasiDokumenAPL2 = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.modalVerifikasiDokumenAPL2 = false;
                self.showModalPasswordVerifikasiDokumen = true;
            });
        },
        checkPasswordVerifikasiDokumen() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
                    username: this.username,
                    password: this.password,
                    is_one_time: false,
                })
                .then((response) => {
                    var data_login = response.data;
                    // check status login
                    if (data_login.status_login == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Oopss",
                            text: data_login.message_login,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    } else {
                        this.showModalPasswordVerifikasiDokumen = false;
                        this.storeVerifikasiDokumenAPL02();
                    }
                })
                .catch((error) => {
                    var message = error.response.data.data.error;
                    if (message == "Session has been used") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
                        });
                    } else if (message == "Account cant find") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Periksa Kembali Username & Password Anda!",
                        });
                    } else if (message == "Something went wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Terjadi Kesalahan, Coba lagi nanti",
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun tidak ditemukan, periksa kembali username & password anda",
                        });
                    }
                });
        },
        getFormDokumenMUK(dokumen_id) {
            let self = this;
            self.formPreviewDocMUK = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/preview-dokumen-muk", {
                    params: {
                        pra_asesmen_id: self.id,
                        pra_asesmen_asesi_dokumen_id: dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.formPreviewDocMUK = res_fix.data.pdf;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });

            self.modalPreviewDokumenMUK = true;
        },
        // modalDokumenPendaftaran('FR.APL 01', item.pendaftaran_asesi.path_dokumen_apl_1, item.id, item.asesi_id, item.pendaftaran_id)
        modalDokumenPendaftaran(nama_dokumen, path, id_pendaftaran_asesi, asesi_id, pendaftaran_id) {
            let self = this;
            self.nama_dokumen = nama_dokumen;
            self.asesi_id = asesi_id;
            self.pendaftaran_id = pendaftaran_id;
            self.id_pendaftaran_asesi = id_pendaftaran_asesi;
            self.linkPreviewForm = process.env.VUE_APP_BACKEND_URL + "/" + path;
            console.log(self.linkPreviewForm);
            if (nama_dokumen == "FR.APL 01") {
                self.getDokumenPersyaratanDasar();
            }
            if (nama_dokumen == "FR.APL 02") {
                self.getDokumenRelevan();
            }

            self.showModalPreviewForm = true;
        },
        getDokumenPersyaratanDasar() {
            let self = this;
            self.loadingDokumen = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/get-dokumen-persyaratan-dasar", {
                    params: {
                        pendaftaran_id: self.pra_asesmen.jadwal_sertifikasi_id,
                        pendaftaran_asesi_id: self.id_pendaftaran_asesi,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.dokumen_persyaratan_dasar = res_fix.data;
                    self.status_dokumen_verifikasi_terima = res_fix.status_dokumen;
                    self.loadingDokumen = false;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getDokumenRelevan() {
            let self = this;
            self.loadingDokumen = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/get-dokumen-relevan", {
                    params: {
                        pendaftaran_id: self.pra_asesmen.jadwal_sertifikasi_id,
                        pendaftaran_asesi_id: self.id_pendaftaran_asesi,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.dokumen_relevan = res_fix.data;
                    self.loadingDokumen = false;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getDataMetodeSertifikasi() {
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-metode-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsMetodeSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getPreviewSuratTugas(pra_asesmen_asesor_id = null) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_surat_tugas = null;
            self.showModalSuratTugas = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/generate-surat-tugas/" + self.id + "/" + pra_asesmen_asesor_id, {
                    params: {
                        id: self.id,
                        dokumen_surat_tugas: "validasi",
                        pra_asesmen_asesor_id: pra_asesmen_asesor_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.path_surat_tugas = res_fix.pdf;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        // pengisian dokumen pra asesmen
        pengisianDokumen(pra_asesmen_asesi_dokumen_id) {
            let self = this;
            self.form_dokumen_muk_pra_asesmen = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.showModalPengisianDokumen = true;
            self.pra_asesmen_asesi_dokumen_id = pra_asesmen_asesi_dokumen_id;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/get-form-dokumen-muk", {
                    params: {
                        pra_asesmen_asesi_dokumen_id: pra_asesmen_asesi_dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    console.log(response.data.html);
                    self.form_dokumen_muk_pra_asesmen = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        storeInputDokumen() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var form = $("#form-dokumen");
            var mydata = new FormData(form[0]);
            mydata.append("pra_asesmen_asesi_dokumen_id", self.pra_asesmen_asesi_dokumen_id);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/store-form-dokumen-muk", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Pengisian dokumen berhasil disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.showModalPengisianDokumen = false;
                    self.getDetailPraAsesmen();
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab active class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Pra Asesmen</span>
                                </template>
                                <!-- Status Menunggu Manager Sertifikasi LSP DAN BELUM PILIH ASESOR -->
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <table class="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th style="width: 30%">Tanggal Surat Tugas</th>
                                                        <td>{{ pra_asesmen.tanggal_surat_tugas }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Nomor Surat Tugas</th>
                                                        <td>{{ pra_asesmen.nomor_surat_tugas }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Perihal</th>
                                                        <td>{{ pra_asesmen.perihal_surat_tugas }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Nomor Pendaftaran</th>
                                                        <td>{{ pendaftaran.pendaftaran_nomor }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Status Pra Asesmen</th>
                                                        <td>{{ pra_asesmen.status_nama }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div v-if="asesor_all">
                                        <h5>Daftar Asesor</h5>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th style="width: 15px">No</th>
                                                    <th>MET</th>
                                                    <th>Asesor</th>
                                                    <th>Jabatan</th>
                                                    <th>Status Kesediaan</th>
                                                    <th>Surat Tugas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="asesor_all.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in asesor_all" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ item?.data_asesor?.kode_asesor }}</td>
                                                    <td>{{ item?.pegawai?.npk }} - {{ item.asesor_nama }}</td>
                                                    <td>
                                                        {{ item.sebagai }}
                                                    </td>
                                                    <td class="text-center">
                                                        <div v-if="item.asesor_id == session.pegawai_id || item?.pegawai?.npk == sessionUsername || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070' || sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1'">
                                                            <div v-if="pra_asesmen.status_id == 'c7dafdd6-56b5-11ee-b5b8-0242ac110006'">
                                                                <div v-if="item.status_kesediaan">
                                                                    <div class="btn-group" v-if="item.status_kesediaan == 'Bersedia'">
                                                                        <b-button v-on:click="verifikasiKehadiran('Roleback', item.asesor_id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                                                        <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                                                    </div>
                                                                    <div class="btn-group" v-else-if="item.status_kesediaan == 'Tidak Bersedia'">
                                                                        <b-button v-on:click="verifikasiKehadiran('Roleback', item.asesor_id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                                                        <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                                                    </div>
                                                                    <div class="btn-group" v-else></div>
                                                                    <div v-if="item.status_kesediaan == 'Tidak Bersedia' && item.notes">
                                                                        <br />
                                                                        Alasan Tidak Bersedia: {{ item.notes }}
                                                                    </div>
                                                                </div>
                                                                <div v-else>
                                                                    <b-button v-on:click="verifikasiKehadiran('Tidak Bersedia', item.asesor_id, index)" type="button" variant="danger" class="btn-sm"><i class="fa fa-times"></i> Tidak Bersedia</b-button>
                                                                    <b-button v-on:click="verifikasiKehadiran('Bersedia', item.asesor_id, index)" type="button" variant="success" class="btn-sm"><i class="fa fa-check"></i> Bersedia</b-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="item.status_kesediaan != null">
                                                                <div class="btn-group" v-if="item.status_kesediaan == 'Bersedia'">
                                                                    <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                                                </div>
                                                                <div class="btn-group" v-else-if="item.status_kesediaan == 'Tidak Bersedia'">
                                                                    <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                                                </div>
                                                                <div class="btn-group" v-else></div>
                                                                <div v-if="item.status_kesediaan == 'Tidak Bersedia' && item.notes">
                                                                    <br />
                                                                    Alasan Tidak Bersedia: {{ item.notes }}
                                                                </div>
                                                            </div>
                                                            <div v-else>Data Belum Tersedia</div>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info" v-on:click="getPreviewSuratTugas(item.id)"><i class="fas fa-eye"></i> Lihat Surat Tugas</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="form-group" v-if="is_asesor_bersedia">
                                        <h5>Daftar Asesi</h5>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th style="width: 15px">No</th>
                                                    <th>Nama Asesi</th>
                                                    <th>Nomor Registrasi</th>
                                                    <th>Nama Asesor Kompetensi</th>
                                                    <th>Nama Asesor peninjau</th>
                                                    <th>Metode Sertifikasi</th>
                                                    <th>Tempat Uji Kompetensi</th>
                                                    <th>Dokumen Asesi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="loadingTable">
                                                    <td colspan="4"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                </tr>
                                                <tr v-else-if="pra_asesmen_asesi.length == 0">
                                                    <td colspan="4">Dokumen Tidak Tersedia</td>
                                                </tr>
                                                <tr v-else v-for="(item, index) in pra_asesmen_asesi" :key="index">
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>{{ item?.asesi?.npk }} - {{ item.asesi_nama }}</td>

                                                    <td>
                                                        <div v-if="item?.no_registrasi">
                                                            {{ item.no_registrasi }}
                                                        </div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item?.pra_asesmen_asesor?.asesor_nama">{{ item?.pra_asesmen_asesor?.pegawai?.npk }} - {{ item?.pra_asesmen_asesor?.asesor_nama }}</div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item?.pra_asesmen_peninjau?.asesor_nama">{{ item?.pra_asesmen_peninjau?.pegawai?.npk }} - {{ item?.pra_asesmen_peninjau?.asesor_nama }}</div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item?.metode_sertifikasi_nama">{{ item?.metode_sertifikasi_nama }}</div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item?.tuk_nama">{{ item?.tuk_nama }}</div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item.pra_asesmen_asesi_dokumen[0]">
                                                            <ol style="list-style-type: decimal">
                                                                <li
                                                                    v-for="(dokumen, index_dokumen) in item.pra_asesmen_asesi_dokumen"
                                                                    :key="index_dokumen"
                                                                    v-bind:class="{
                                                                        'bg-warning': dokumen.status_dokumen == 'belum-input' && dokumen_pra_asesmen_input.includes(dokumen.dokumen_id) && hak_akses && pra_asesmen.status_id == '15f6e2e6-6971-11ee-a9e6-0242ac11000a',
                                                                        'bg-success': dokumen.status_dokumen == 'sudah-input' && dokumen_pra_asesmen_input.includes(dokumen.dokumen_id) && hak_akses && pra_asesmen.status_id == '15f6e2e6-6971-11ee-a9e6-0242ac11000a',
                                                                    }">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            {{ dokumen.dokumen_nama }}
                                                                        </div>
                                                                        <div class="col text-end">
                                                                            <div class="row">
                                                                                <div class="col">
                                                                                    <button type="button" v-on:click="getFormDokumenMUK(dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <div v-if="(sebagai_asesor == 'Asesor Kompetensi' || sebagai_asesor == 'semua') && pra_asesmen.status_id != '7f640402-539b-11ee-9fb1-0242ac110006'">
                                                                                        <div v-if="dokumen_pra_asesmen_input.includes(dokumen.dokumen_id) && hak_akses">
                                                                                            <button @click="pengisianDokumen(dokumen.id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Lengkapi Dokumen</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="pra_asesmen.status_id == 'c45e42ae-5399-11ee-96aa-0242ac110006'">
                                                                <button class="btn btn-success btn-sm m-1 rounded-0" v-on:click="modalPreviewAPL2(item.pendaftaran_asesi.path_dokumen_apl_1, 'APL1')"><i class="fa fa-eye"></i> FR.APL 01</button>
                                                                <div v-if="sebagai_asesor == 'Asesor Kompetensi'">
                                                                    <div v-if="item.path_dokumen_apl_2">
                                                                        <button class="btn btn-success btn-sm m-1 rounded-0" v-on:click="modalPreviewAPL2(item.dokumen_apl_2, 'APL2')"><i class="fa fa-eye"></i> FR.APL 02</button>
                                                                    </div>
                                                                    <div v-else>
                                                                        <button
                                                                            class="btn btn-sm m-1 rounded-0"
                                                                            v-bind:class="{
                                                                                'btn-warning': item.is_verifikasi_apl_2 == false,
                                                                                'btn-success': item.is_verifikasi_apl_2 == true,
                                                                            }"
                                                                            v-on:click="verifikasiDokumenMUK('b88c9f10-39dc-11ee-9a36-0242ac110009', item.id)">
                                                                            <i class="fa fa-eye"></i> FR.APL 02
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div v-else>
                                                                    <div v-if="item.path_dokumen_apl_2">
                                                                        <button class="btn btn-success btn-sm m-1 rounded-0" v-on:click="modalPreviewAPL2(item.dokumen_apl_2, 'APL2')"><i class="fa fa-eye"></i> FR.APL 02</button>
                                                                    </div>
                                                                    <div v-else>
                                                                        <button
                                                                            class="btn btn-sm m-1 rounded-0"
                                                                            v-bind:class="{
                                                                                'btn-warning': item.is_verifikasi_apl_2 == false,
                                                                                'btn-success': item.is_verifikasi_apl_2 == true,
                                                                            }"
                                                                            v-on:click="modalPreviewAPL2(item.dokumen_apl_2, 'APL2')">
                                                                            <i class="fa fa-eye"></i> FR.APL 02
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <button class="btn btn-success rounded-pill m-1 p-2" @click="modalDokumenPendaftaran('FR.APL 01', item.pendaftaran_asesi.path_dokumen_apl_1, item.pendaftaran_asesi.id, item.asesi_id, item.pendaftaran_id)">FR.APL 01</button>
                                                                <button
                                                                    class="btn rounded-pill m-1 p-2"
                                                                    v-bind:class="{
                                                                        'btn-warning': item.is_verifikasi_apl_2 == false,
                                                                        'btn-success': item.is_verifikasi_apl_2 == true,
                                                                    }"
                                                                    @click="modalDokumenPendaftaran('FR.APL 02', item.dokumen_apl_2, item.pendaftaran_asesi.id, item.asesi_id, item.pendaftaran_id)">
                                                                    FR.APL 02
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div v-if="hak_akses">
                                            <div class="row" v-if="pra_asesmen.status_id == '6762a1dc-4e10-11ee-afa5-0242ac110006'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <!-- <b-button v-on:click="simpanPraAsesmen()" type="button" class="m-1 btn-sm" variant="primary"><i class="fas fa-save"></i>
                                                        Simpan
                                                    </b-button> -->
                                                        <!-- <b-button v-on:click="statusValidasiModal('tolak')" type="button" variant="danger" class="m-1 btn-sm"><i class="fa fa-times"></i> Tolak</b-button> -->
                                                        <b-button v-on:click="statusValidasiModal('terima')" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-check"></i> Submit</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="pra_asesmen.status_id == 'ad1eb36c-5399-11ee-96aa-0242ac110006'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <b-button v-if="(status_sekarang.id = 'aeffdb24-51fb-11ee-971e-0242ac110006')" v-on:click="modalTerbitkanSuratTugas()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="pra_asesmen.status_id == '2e5284fc-4e10-11ee-afa5-0242ac110006'">
                                                <div class="col-md-12 col-md-6">
                                                    <div class="text-end">
                                                        <b-button v-on:click="submitNextStatus()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="pra_asesmen.status_id == 'c45e42ae-5399-11ee-96aa-0242ac110006' && sebagai_asesor == 'Asesor Kompetensi'">
                                            <div class="col-md-12 col-md-6">
                                                <div class="text-end" v-if="status_verifikasi_dokumen_apl_2">
                                                    <b-button v-on:click="modalVerifikasiDokumen()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                </div>
                                                <div class="text-end" v-else>
                                                    <b-button type="button" variant="secondary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="pra_asesmen.status_id == '15f6e2e6-6971-11ee-a9e6-0242ac11000a' && status_pengisian_dokumen != true">
                                            <div class="col-md-12 col-md-6 text-end">
                                                <b-button v-on:click="selesaikanProsesPengisian()" type="button" variant="success" class="m-1 btn-sm"><i class="fa fa-save"></i> Selesaikan Proses Pengisian Dokumen Pra-Asesmen </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="my-0">Timeline</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12" style="overflow-x: scroll">
                                <ul class="timeline timeline-horizontal">
                                    <li class="timeline-item" v-for="(val, key) in pra_asesmen_timeline" :key="key">
                                        <!--v-if-->
                                        <div class="timeline-badge success">
                                            <i class="fa fa-check" style="margin-top: 12px"></i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <b class="timeline-title">{{ val.pejabat_nama }}</b>
                                                <p>
                                                    <small class="text-muted"><i class="fa fa-clock-o"></i> {{ fullDateTimeFormat(val.created_at) }}</small>
                                                </p>
                                            </div>
                                            <div class="badge bg-info">{{ val.status_nama }}</div>
                                            <br />
                                            <div class="timeline-body">
                                                <b>Catatan : </b><br />
                                                <p>{{ val.notes }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="showModalSuratTugas" title="Preview Surat Tugas" title-class="text-black font-18" size="xl" hide-footer>
        <div class="row">
            <div class="card-body">
                <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalStatusKehadiran" title="Alasan Tidak Bersedia" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="prosesVerifikasiKehadiran">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <textarea v-model="catatan_kehadiran" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPassword" title="Konfirmasi Password" title-class="text-black font-18" size="sm" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="checkPassword">
                <div class="form-group">
                    <input type="password" v-model="password" id="" class="form-control" />
                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-primary mt-3" @click="checkPassword"><i class="fas fa-save"></i> Proses</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPasswordVerifikasiDokumen" title="Konfirmasi Password" title-class="text-black font-18" size="sm" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="checkPassword">
                <div class="form-group">
                    <input type="password" v-model="password" id="" class="form-control" />
                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-primary mt-3" @click="checkPasswordVerifikasiDokumen"><i class="fas fa-save"></i> Proses</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewDokumen" title="Preview Dokumen" title-class="text-black font-18" size="xl" hide-footer>
        <div class="row">
            <div class="card-body">
                <iframe v-bind:src="path_preview_dokumen" width="100%" height="400"></iframe>
            </div>
            <!-- <div class="card-footer">
                <div class="row text-end">
                    <div class="col-md-6"></div>
                    <div class="col-md-6 text-end">
                        <div v-if="nama_dokumen == 'APL2' && tipe_asesor == 'Asesor Kompetensi'">
                            <b-button type="button" variant="danger" class="m-1"><i class="fa fa-times"></i> Tolak</b-button>
                            <b-button type="button" variant="success" class="m-1"><i class="fa fa-check"></i> Terima</b-button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </b-modal>

    <b-modal v-model="showModalVerifikasiDokumen" size="lg" title="Jadwal Asesmen" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="form-group">
                    <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                        <thead class="bg-dark text-center text-white">
                            <tr>
                                <th>No</th>
                                <th>Nama Asesi</th>
                                <th>No Registrasi</th>
                                <th>Tanggal Asesmen</th>
                                <th>Metode Sertifikasi</th>
                                <th>Tempat Uji Kompetensi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pra_asesmen_asesi" :key="index">
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>{{ item?.asesi?.npk }} - {{ item.asesi_nama }}</td>

                                <td>
                                    <div v-if="item?.no_registrasi">
                                        {{ item.no_registrasi }}
                                    </div>
                                    <div v-else>Data Belum Tersedia</div>
                                </td>
                                <td>
                                    <input type="date" class="form-control" v-model="item.tanggal_usulan_asesmen" />
                                </td>
                                <td>
                                    <v-select :options="optionsMetodeSertifikasi" label="nama_metode_sertifikasi" v-model="item.metode_sertifikasi" placeholder="Pilih Metode Sertifikasi"></v-select>
                                </td>
                                <td>
                                    <v-select :options="optionsTUK" label="tuk_nama" v-model="item.tuk" placeholder="Pilih TUK"></v-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-primary mt-3" @click="storePemilihanMetodeAsesi"><i class="fas fa-save"></i> Proses</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalValidasi" title="Alasan Penolakan" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="storeUbahStatusPraAsesmen">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <textarea v-model="catatan" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalAsesor" title="Penetapan Asesor Pra Asesmen" size="lg" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <table class="table table-bordered">
                    <thead class="bg-dark text-light">
                        <tr>
                            <td style="width: 15px">No</td>
                            <td>Nama Asesi</td>
                            <td>Nama Asesor Kompetensi</td>
                            <td>Nama Asesor Peninjau</td>
                            <td>Tanggal</td>
                            <td>Tempat</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in pra_asesmen_asesi" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>{{ item?.asesi?.npk }} - {{ item.asesi_nama }}</td>
                            <td>
                                <v-select :options="optionsAsesor" label="nama_asesor" v-model="item.asesor_kompetensi" placeholder="Pilih Asesor Kompetensi" @update:modelValue="storePemilihanAsesor()">
                                    <template v-slot:option="option">{{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_kompetensi }} Frekuensi Asesor Kompetensi</template>
                                    <template v-slot:selected-option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_kompetensi }} Frekuensi Asesor Kompetensi</template>
                                </v-select>
                            </td>
                            <td>
                                <v-select :options="optionsAsesor" label="nama_asesor" v-model="item.asesor_peninjau" placeholder="Pilih Asesor Peninjau" @update:modelValue="storePemilihanAsesor()">
                                    <template v-slot:option="option">{{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_peninjau }} Frekuensi Asesor Peninjau</template>
                                    <template v-slot:selected-option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_peninjau }} Frekuensi Asesor Peninjau</template>
                                </v-select>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.tanggal_pra_asesmen" />
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="item.tempat_pra_asesmen" />
                            </td>
                        </tr>
                    </tbody>
                </table>
              
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Nomor Pra Asesmen</label>
                    <input type="text" v-model="nomor_pra_asesmen" class="form-control" />
                </div>
            
                <div class="form-group mt-2">
                    <div v-if="loading_store_asesor"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                    <iframe v-bind:src="iframe_pdf_surat_tugas" width="100%" height="400" v-else></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="disclaimer_asesor" /> Apakah Anda yakin? asesor yang dipilih akan segera mendapat notifikasi pra asesmen</div>
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="storeUbahStatusPraAsesmen()" v-if="disclaimer_asesor"><i class="fas fa-save"></i> Simpan</button>
                <button class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalTerbitkanSuratTugas" title="Preview Surat Tugas" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Tugas</strong></td>
                                    <td>: {{ pra_asesmen.nomor_surat_tugas }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Tugas</strong></td>
                                    <td>: {{ fullDateTimeFormat(pra_asesmen.tanggal_surat_tugas) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin ingin menerbitkan surat tugas tersebut?</div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalTerbitkanSuratBalasan = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" v-if="checklist" @click="modalPassword"><i class="fas fa-save"></i> Terbitkan</button>
                    <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Terbitkan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalVerifikasiDokumenAPL2" title="Verifikasi Dokumen FR. APL 02" title-class="text-black font-18" size="lg" hide-footer>
        <div class="row">
            <div class="card-body">
                <form id="form-dokumen-pendaftaran">
                    <div class="form-group">
                        <div v-html="form_dokumen_muk"></div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><input type="checkbox" v-model="disclaimerVerifikasiForm" /> Apakah ingin verifikasi form tersebut?</div>
                    </div>
                    <div class="col-md-6 text-end">
                        <b-button v-if="disclaimerVerifikasiForm == true" type="button" class="btn-sm" variant="primary" @click="modalPasswordVerifikasiDokumen()"><i class="fas fa-save"></i> Verifikasi Dokumen APL 02</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Verifikasi Dokumen APL 02</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal v-model="modalPreviewDokumenMUK" title="Preview Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <!-- <form @submit.prevent="StorePreviewMUK"> -->
            <!-- <div v-html="formPreviewDocMUK"></div> -->

            <iframe v-bind:src="formPreviewDocMUK" width="100%" height="400"></iframe>
            <!-- <div class="card-footer text-end">
                    <button class="btn btn-primary"><i class="fas fa-save"></i> Simpan</button>
                </div> -->
            <!-- </form> -->
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewForm" :title="nama_dokumen" title-class="text-black font-18" size="xl" hide-footer>
        <div class="row">
            <div class="card-body">
                <div class="row" v-if="nama_dokumen == 'FR.APL 01'">
                    <div class="col-md-12">
                        <h4>Bukti Persyaratan Dasar</h4>
                        <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                            <thead class="bg-dark text-center text-white">
                                <tr>
                                    <th style="width: 5%">No</th>
                                    <th style="width: 60%">Nama Dokumen</th>
                                    <th>Preview Dokumen</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loadingDokumen">
                                    <td colspan="4" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                </tr>
                                <tr v-else-if="dokumen_persyaratan_dasar == ''">
                                    <td class="text-center" colspan="5">Data Tidak Tersedia</td>
                                </tr>
                                <tr v-else v-for="(item, key_data) in dokumen_persyaratan_dasar" :key="key_data">
                                    <td class="text-center">
                                        {{ key_data + 1 }}
                                    </td>
                                    <td>
                                        {{ item.bukti_dokumen_nama }}
                                    </td>
                                    <td class="text-center">
                                        <a :href="backend_url + item.path_dokumen" target="_blank" class="btn btn-sm btn-info"><i class="fas fa-eye"></i> Lihat Dokumen</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row" v-else>
                    <h4>Bukti Dokumen Relevan</h4>
                    <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                        <thead class="bg-dark text-center text-white">
                            <tr>
                                <th style="width: 5%">No</th>
                                <th style="width: 60%">Nama Dokumen</th>
                                <th>Preview Dokumen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="loadingDokumen">
                                <td colspan="4" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                            </tr>
                            <tr v-else-if="dokumen_relevan == ''">
                                <td class="text-center" colspan="5">Data Tidak Tersedia</td>
                            </tr>
                            <tr v-else v-for="(item, key_data) in dokumen_relevan" :key="key_data">
                                <td class="text-center">
                                    {{ key_data + 1 }}
                                </td>
                                <td>
                                    {{ item.bukti_dokumen_nama }}
                                </td>
                                <td class="text-center">
                                    <a :href="backend_url + item.path_dokumen" target="_blank" class="btn btn-sm btn-info"><i class="fas fa-eye"></i> Lihat Dokumen</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 mt-3">
                    <iframe v-bind:src="linkPreviewForm" width="100%" height="400"></iframe>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalPengisianDokumen" title="Pengisian Dokumen" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form id="form-dokumen">
                <div class="form-group">
                    <div v-html="form_dokumen_muk_pra_asesmen"></div>
                </div>
            </form>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="storeInputDokumen"><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
</template>
